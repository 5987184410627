/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { Observable, catchError, lastValueFrom, map, of } from 'rxjs';
import { BaseService, FormResp } from './base.service';
import { TIN } from '@app/types/taxpayer.type';

export interface Tin {
  id: number;
  companyName: string;
  brNumber: string;
  tin: string;
  salesTaxNumber: string;
  serviceTaxNumber: string;
  tourismTaxNumber: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  internalReferenceId: string;
  emailId: string;
  contactNumber: string;
  logoPath?: string | null;
  msicCodes: string;
  isSelected: boolean;

  clientId?: string;
  clientSecret1?: string;
  clientSecret2?: string;
}

interface UpdatePOB {
  pobCode: string;
  pobName: string;
  salesTaxNumber: string;
  serviceTaxNumber: string;
  tourismTaxNumber: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  internalReferenceId: string;
  emailId: string;
  contactNumber: string;
  defaultPob: boolean;
  tinId: number;
  msicCodes: string | null;
}

export interface POB {
  id: number;
  pobCode: string;
  pobName: string;
  salesTaxNumber: string;
  serviceTaxNumber: string;
  tourismTaxNumber: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  internalReferenceId: string;
  emailId: string;
  contactNumber: string;
  defaultPob: boolean;
  tinId: number;
  msicCodes: string | null;
  isSelected: boolean;
}

interface UpdateTin {
  companyName: string;
  brNumber: string;
  tin: string;
  salesTaxNumber?: string;
  serviceTaxNumber?: string;
  tourismTaxNumber?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  internalReferenceId?: string;
  emailId?: string;
  contactNumber?: string;
  msicCodesList?: string[];
}

export interface VerifyTin {
  idType: string;
  idValue: string;
  tin: string;
}

@Injectable({
  providedIn: 'root',
})
export class TinsService extends BaseService {
  tins: Tin[] = [];
  pobs: POB[] = [];

  getTins(refresh: boolean = false): Observable<Tin[]> {
    if (this.tins.length && !refresh) return of(this.tins);

    return this.httpClient.get<Tin[]>('/myportal/tinDetails').pipe(
      map((resp: any) => {
        if (resp.status) this.tins = resp.response;
        return this.tins;
      }),
    );
  }

  getTinInfo(tinId: number): Observable<Tin> {
    const params = { tinIds: tinId };
    return this.httpClient.get<Tin>('/myportal/tinDetails', { params }).pipe(
      map((resp: any) => {
        if (resp.status) return resp.response?.[0] ?? null;
        return null;
      }),
    );
  }

  async getTinsList(): Promise<TIN[]> {
    if (this.tins.length === 0) {
      await lastValueFrom(this.getTins());
    }

    return this.tins.map((tin) => ({ id: tin.id, tin: tin.tin, name: tin.companyName }));
  }

  createTin(payload: UpdateTin) {
    return this.httpClient
      .post('/myportal/add/tin', payload)
      .pipe(map((resp: any) => this.booleanResp(resp)));
  }

  uploadTinLogo(tinId: number, payload: any) {
    const options = { params: { tinId } };
    return this.httpClient
      .post('/myportal/upload/tin/logo', payload, options)
      .pipe(map((resp: any) => this.handleResp(resp)));
  }

  updateTin(tinId: number, payload: UpdateTin): Observable<boolean> {
    const options = { params: { tinId } };
    return this.httpClient
      .post('/myportal/update/tin', payload, options)
      .pipe(map((resp: any) => this.booleanResp(resp)));
  }

  addUpdateTin(payload: UpdateTin, tinId?: number): Observable<FormResp> {
    let action = 'add';
    const options = { params: {} };
    if (tinId) {
      action = 'update';
      options.params = { tinId };
    }

    return this.httpClient.post(`/myportal/${action}/tin`, payload, options).pipe(
      map((resp: any) => this.formResp(resp)),
      catchError((err) => of(err.error)),
    );
  }

  deleteTin(tinId: number): Observable<boolean> {
    return this.httpClient
      .delete('/myportal/tinDetails', { params: { tinId } })
      .pipe(map((resp: any) => this.booleanResp(resp)));
  }

  verifyTin(verifyTin: VerifyTin): Observable<boolean> {
    return this.httpClient
      .get(`/myportal/validate/tin/${verifyTin.tin}`, {
        params: { idType: verifyTin.idType, idValue: verifyTin.idValue },
      })
      .pipe(map((resp: any) => this.booleanResp(resp)));
  }

  getPOBs(refresh: boolean = false): Observable<POB[]> {
    if (this.pobs.length && !refresh) return of(this.pobs);

    return this.httpClient.get<Tin[]>('/myportal/pobDetails').pipe(
      map((resp: any) => {
        if (resp.status) this.pobs = resp.response;
        return this.pobs;
      }),
    );
  }

  findPOB(tinId: number): Observable<POB[]> {
    const pobs = this.pobs.filter((pob) => pob.tinId === tinId);
    return of(pobs);
  }

  addUpdatePob(payload: UpdatePOB, pobId?: number): Observable<FormResp> {
    let action = 'add';
    const options = { params: {} };
    if (pobId) {
      action = 'update';
      options.params = { pobId };
    }

    return this.httpClient.post(`/myportal/${action}/pob`, payload, options).pipe(
      map((resp: any) => this.formResp(resp)),
      catchError((err) => of(err.error)),
    );
  }

  deletePob(pobId: number): Observable<boolean> {
    return this.httpClient
      .delete('/myportal/pobDetails', { params: { pobId } })
      .pipe(map((resp: any) => this.booleanResp(resp)));
  }

  verifyLHDNKeys(payload: {
    clientId: string;
    clientSecret: string;
    clientSecret2: string | null;
  }): Observable<boolean> {
    payload.clientSecret = btoa(payload.clientSecret);
    if (payload.clientSecret2) payload.clientSecret2 = btoa(payload.clientSecret2);

    return this.httpClient
      .post('/myportal/verify/ci/cs', payload)
      .pipe(map((resp: any) => this.booleanResp(resp)));
  }
}
